import React from "react";
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import {Authenticator} from '@aws-amplify/ui-react';
import NavBar from "./NavBar";
import Home from "./Home";
import {useNavigate} from "react-router-dom";

function App() {
    const navigate = useNavigate();
    const loginRedirect = sessionStorage.getItem('login-redirect');

    React.useEffect(() => {
        if (loginRedirect !== '') {
            sessionStorage.removeItem('login-redirect');
            navigate(loginRedirect);
        }
    }, []);

    return (
        <Authenticator.Provider>
            <div className="App">
                <NavBar/>
                <Home />
            </div>
        </Authenticator.Provider>
    );
}

export default App;
