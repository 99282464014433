
import './Home.css'

function Home() {
    return (
        <div className="Home">
            <p>Welcome to {process.env.REACT_APP_NAME}!</p>
        </div>
    );
}

export default Home;