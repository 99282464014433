import './NavBar.css'
import {useAuthenticator} from '@aws-amplify/ui-react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth'

function NavBar() {
    const { authStatus, user, signOut } = useAuthenticator((context) => [context.authStatus, context.user]);
    const location = useLocation();

    function AuthenticatedUser() {
        const navigate = useNavigate();
        //TODO Select cause dynamically: https://ivolunteer-za.atlassian.net/browse/IV-36
        return (
            <span>
                <button onClick={() => {navigate('/schedules/cb097818-7e35-4210-a43e-45032692d2f4')}}>My Schedule</button>
                <button onClick={signOut}>Sign out ({user.attributes.given_name})</button>
            </span>
        )
    }

    function SignInButton() {
        const handleButtonClick = async () => {
            try {
                sessionStorage.setItem('login-redirect', location.pathname + location.search);
                await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito})
            } catch (error) {
                console.log('Error signing in:', error);
            }
        };

        return (
            <button onClick={handleButtonClick}>
                Sign In
            </button>
        );
    }

    return (
        <div className="NavBar">
            <div className="NavLogo">
                <Link to={`/`}>
                    <img className="AppLogo" src="/logo192.png" alt="logo"/>
                </Link>
            </div>
            <div className="NavLinks">
                {authStatus === 'authenticated' && (<AuthenticatedUser/>)}
                {authStatus !== 'authenticated' && (<SignInButton/>)}
            </div>
        </div>
    );
}

export default NavBar;