import {useParams, useSearchParams} from "react-router-dom";
import React from "react";
import {API} from "aws-amplify";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import NavBar from "./NavBar";
import './Activate.css';

function Activate() {
    const { authStatus, user, signOut } = useAuthenticator((context) => [context.authStatus, context.user]);
    const [apiInProgress, setApiInProgress] = React.useState(false);
    const [apiData, setApiData] = React.useState("");
    const [apiError, setApiError] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    let { target } = useParams();

    const handleActivateClick = async () => {
        setApiInProgress(true);
        let targetName = 'unknown-target';
        if (target === 't') {
            targetName = 'telegram-chat-id';
        }
        setApiError('');
        const promise = API.post(process.env.REACT_APP_API_NAME, "activate", {
            body: {
                target_name: targetName,
                one_time_hash: searchParams.get('id')
            }
        });
        try {
            const response = await promise;
            setApiData(response.data);
            setApiInProgress(false);
        } catch (error) {
            setApiInProgress(false);
            console.log(error);
            if (error.response) {
                setApiError(error.response.status + " - " + error.response.data.data);
            } else {
                setApiError('Unknown');
            }
        }
    };

    function ActivateButton() {
        if (apiInProgress) {
            return (<p>Activating..</p>)
        }
        if (apiData !== '') {
            return (<div></div>);
        }
        if (!apiInProgress || apiError !== '') {
            return (
                <button className="SubmitButton" onClick={handleActivateClick}>Activate Telegram</button>
            );
        }
    }

    function ActivateContent() {
        if (target !== 't') {
            return (
                <div>
                    <h1>Unknown activation link</h1>
                    <p>This activation link is unfortunately not supported.</p>
                </div>
            );
        }
        return (
            <div>
                <h1>Activate Telegram Bot</h1>
                <p>Hi {user.attributes.given_name}, thanks for your interest in activating the IVolunteer Telegram bot.</p>
                <p>If you are happy for the Telegram bot to send you important information, please click the <i>Activate</i> button below.</p>
                <p>If you did not request this link, or maybe you're not {user.attributes.given_name}, feel free to ignore this. Consider rather <a href="" onClick={signOut}>signing out</a>.</p>
                <ActivateButton/>
                <br/>
                {apiError !== '' &&
                    <div className="Error">
                        <p>Sorry, something unexpected failed with the activation process. Either retry, or reach out for support.</p>
                    </div>
                }
                {apiData !== '' &&
                    <div className="Success">
                        <p>Activation successful!</p>
                    </div>
                }
            </div>
        );
    }

    function SignInContent() {
        return (
            <div>
                <h1>Activate Telegram Bot</h1>
                <p>In order to activate the IVolunteer Telegram bot, we first need to confirm that you are a registered IVolunteer user.</p>
                <p>To do this, please sign in first.</p>
                <p>
                    If you do not have active login credentials, please reach out to <a href="mailto:admin@ivolunteer.co.za">admin@ivolunteer.co.za</a> for registration,
                    as self-service registration is not yet supported.
                </p>
            </div>
        );
    }

    return (
        <div className="App">
            <NavBar/>
            {authStatus === 'authenticated' && (<ActivateContent/>)}
            {authStatus !== 'authenticated' && (<SignInContent/>)}
        </div>
    );
}

export default () => (
    <Authenticator.Provider>
        <Activate />
    </Authenticator.Provider>
);