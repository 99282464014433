import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Activate from './Activate';
import Schedule from "./Schedule";
import NoRouteError from './NoRouteError';
import reportWebVitals from './reportWebVitals';
import {Amplify, Auth} from 'aws-amplify';
import awsExports from './aws-exports';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

Amplify.configure(awsExports);

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_ID,
        oauth: {
            domain: 'auth.' + process.env.REACT_APP_BASE_DOMAIN,
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://' + process.env.REACT_APP_BASE_DOMAIN + '/',
            redirectSignOut: 'https://' + process.env.REACT_APP_BASE_DOMAIN + '/',
            responseType: 'code',
        }
    },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_API_NAME,
                endpoint: process.env.REACT_APP_API_ENDPOINT,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
        ],
    },
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <NoRouteError />,
    },
    {
        path: "/activate/:target",
        element: <Activate/>
    },
    {
        path: "/schedules/:causeId",
        element: <Schedule/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
