import {useParams} from "react-router-dom";
import React from "react";
import {API} from "aws-amplify";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import './Schedule.css'
import NavBar from "./NavBar";

function Schedule() {
    const { authStatus, user, signOut } = useAuthenticator((context) => [context.authStatus, context.user]);
    const [scheduleLoaded, setScheduleLoaded] = React.useState(false);
    const [scheduleResult, setScheduleResult] = React.useState({});
    let { causeId } = useParams();

    const fetchSchedule = async () => {
        const path = "schedules/" + causeId;
        const promise = API.get(process.env.REACT_APP_API_NAME, path, {});
        try {
            const response = await promise;
            return {success: true, data: response.data};
        } catch (error) {
            console.log('error: ' + error);
            if (error.response) {
                return {success: false, status: error.response.status, data: error.response.data.data};
            } else {
                return {success: false, status: 500, data: 'Unknown'}
            }
        }
    };

    React.useEffect(() => {
        (async () => {
            setScheduleLoaded(false);
            let result = await fetchSchedule();
            if (result.success) {
                setScheduleResult({success: true, scheduleData: JSON.parse(result.data)});
            } else {
                setScheduleResult(result)
            }
            setScheduleLoaded(true);
        })();
    }, []);

    function DailySlotCell({dailySlot}) {
        if (dailySlot == null) {
            return (<td></td>)
        }
        if (dailySlot.existing_group_extension) {
            return
        }
        return (
            <td className="volunteer_slot" rowSpan={dailySlot.time_range_group_size}>
                {
                    dailySlot.volunteers.map((volunteer, index) => {
                        return (
                            <div key={index}>
                                {index > 0 ? '&' : ''} <div className={volunteer.inactive ? "inactive" : ""}>{volunteer.first_name} {volunteer.last_name}</div>
                            </div>
                        )
                    })
                }
            </td>
        )
    }

    function TimeRangeSlotRow({timeRangeSlot}) {
        return (
            <tr className={timeRangeSlot.daily_slots_empty ? "vacant_range" : ""}>
                <td>{timeRangeSlot.time_range.start_time}&nbsp;-&nbsp;{timeRangeSlot.time_range.end_time}</td>
                {
                    timeRangeSlot.daily_slots.map((dailySlot, index) => {
                        return (
                            <DailySlotCell key={index} dailySlot={dailySlot}/>
                        )
                    })
                }
            </tr>
        )
    }

    function WeeklyScheduleTable({weeklySchedule}) {
        return (
            <table className="rotation">
                <thead>
                <tr><th colSpan="8" className="title">{weeklySchedule.week.year}, Week {weeklySchedule.week.week_in_year_num} ({weeklySchedule.week.week_start} - {weeklySchedule.week.week_end})</th></tr>
                <tr>
                    <th>Time Slot</th>
                    {
                        weeklySchedule.week.days_in_week.map((day, index) => {
                            return (
                                <th key={index}>{day.weekday.long_name}<br/>({day.month_in_year_num}/{day.day_in_month_num})</th>
                            )
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    weeklySchedule.time_range_slots.map((timeRangeSlot, index) => {
                        return (
                            <TimeRangeSlotRow key={index} timeRangeSlot={timeRangeSlot}/>
                        )
                    })
                }
                </tbody>
            </table>
        )
    }

    function ScheduleLoadError() {
        let errorDescription = '';
        if (scheduleResult.status === 404) {
            errorDescription = 'Sorry, the requested cause does not exist.';
        } else {
            errorDescription = 'Sorry, we failed to load the schedule for the specified cause. Please retry in a while.';
        }
        return (
            <div className="Error">{errorDescription}</div>
        );
    }

    function ScheduleLoadSuccess({scheduleData}) {
        return (
            <div>
                <h2>{scheduleData.cause.description}: {scheduleData.month.long_month_name} {scheduleData.month.year} schedule</h2>
                {
                    scheduleData.weekly_schedules.map((weeklySchedule, index) => {
                        return (
                            <WeeklyScheduleTable key={index} weeklySchedule={weeklySchedule}/>
                        )
                    })
                }
            </div>
        );
    }

    function Schedule() {
        if (scheduleLoaded) {
            if (scheduleResult.success) {
                return (<ScheduleLoadSuccess scheduleData={scheduleResult.scheduleData}/>);
            } else {
                return (<ScheduleLoadError/>);
            }
        } else {
            return (<div>Loading..</div>);
        }
    }

    function SignInContent() {
        return (
            <div>
                <h1>Your schedule</h1>
                <p>You need to be signed in to view your schedule.</p>
                <p>Please go ahead and log in to view your schedule.</p>
                <p>
                    If you do not have active login credentials, please reach out to <a href="mailto:admin@ivolunteer.co.za">admin@ivolunteer.co.za</a> for registration,
                    as self-service registration is not yet supported.
                </p>
            </div>
        );
    }

    return (
        <div className="App">
            <NavBar/>
            {authStatus === 'authenticated' && (<Schedule/>)}
            {authStatus !== 'authenticated' && (<SignInContent/>)}
        </div>
    );
}

export default () => (
    <Authenticator.Provider>
        <Schedule />
    </Authenticator.Provider>
);